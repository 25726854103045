import React from "react"
import cx from "classnames"
import { connect } from "react-redux"
// import LazyLoad from "react-lazyload"
import { subscribeToNewsletters } from "../../../redux/actions/HomeActions"
import styles from "./free-tours.less"
import { isEmail } from "../../../common/tool/util"
import { t } from "../../../../config/index"

class Component extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDiscountPopup: false,
            isPopupShow: false,
            isError: false,
            errorMsg: "",
        }
        this.inputRef = React.createRef()
    }

    getSubscriptionSuccessPopup = () => (
        <div className={styles.freeTours_popup_main}>
            <div className={
                styles.freeTours_popupbox
            }
            >
                <div
                    className={styles.freeTours_close}
                    onClick={() => this.togglePopup()}
                >
                    X
                </div>
                <div className={styles.freeTours_popup_sub}>
                    {
                        this.state.isDiscountPopup
                            ? (
                                <div>
                                    <h3>
                                        {t("Thank you for joining")}
                                        !
                                    </h3>
                                    <p className={styles.freeTours_text}>
                                        {t("Now take 5% off by using code")}
                                        <b>WLCM-Q22019</b>
                                        {" "}
                                        {t("at checkout")}
                                        .
                                    </p>
                                </div>
                            )
                            : (
                                <div>
                                    <h3>
                                        {t("Thanks for signing up")}
                                        !
                                    </h3>
                                    <p>
                                        {t("CHECK_YOUR_EMAIL")}
                                        !
                                    </p>
                                </div>
                            )
                    }
                </div>
            </div>
            <div
                className={styles.freeTours_popup_overlay}
                onClick={() => this.togglePopup()}
            />
        </div>
    )

    subscribeForUpdates = e => {
        e.preventDefault()
        const val = this.inputRef.current.value
        if (val && isEmail(val)) {
            this.toggleError()
            this.props.subscribeToNewsletters(
                val,
                (successErr = "") => this.togglePopup(successErr),
                () => this.toggleError(true),
            )
        } else {
            this.toggleError(true, t("Please enter a valid email address"))
        }
    }

    toggleError = (isError = false, errorMsg = "") => {
        this.setState(state => ({
            isError,
            errorMsg: errorMsg !== "" ? t(errorMsg) : state.errorMsg,
        }))
    }

    togglePopup = (successErr = "") => {
        if (successErr) {
            this.toggleError(true, successErr)
        } else {
            this.setState(state => ({
                isPopupShow: !state.isPopupShow,
            }))
        }
    }

    render() {
        return (
            // Updated state is not re rendering due to LazyLoad
            // <LazyLoad height={282} offset={200}>
            <section className={styles.freeTours}>
                <div
                    className={styles.freeTours_overlay}
                >
                    <div className={styles.freeTours_container}>
                        <h2>
                            {`${t("GET 5% OFF YOUR FIRST ORDER")}!`}
                        </h2>
                        <p>
                            {t("YOU_WILL_ALSO_RECEIVE_EXCLUSIVE_OFFERS")}
                        </p>
                        <div className={styles.shareLink}>
                            <div className={styles.shareForm}>
                                <div className={styles.shareFormSub}>
                                    <form onSubmit={this.subscribeForUpdates}>
                                        <input
                                            type="email"
                                            placeholder={t("Your Email Address")}
                                            className={
                                                this.state.isError
                                                    ? `${styles.inputText} ${styles.show_border}`
                                                    : styles.inputText
                                            }
                                            ref={this.inputRef}
                                        />
                                        <input
                                            type="submit"
                                            value=""
                                            className={styles.subscribeButton}
                                            onClick={this.subscribeForUpdates}
                                        />
                                    </form>
                                    <div className={cx(styles.error_message, { "dis-none": !this.state.isError })}>
                                        {this.state.errorMsg}
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isPopupShow && this.getSubscriptionSuccessPopup()}
                </div>
            </section>
            // </LazyLoad>
        )
    }
}

export default connect(null, { subscribeToNewsletters })(Component)
